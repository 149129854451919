/* Common Imports */

import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";

/* Component Imports */

import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  h1: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "3rem",
    },
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      fontSize: "2.5rem",
    },
  },
  h2: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "2.75rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "2.5rem",
    },
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      fontSize: "2rem",
    },
  },
  h3: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "2rem",
    },
  },
  h4: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.75rem",
    },
  },
  h5: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.5rem",
    },
  },
  h6: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.25rem",
    },
  },
  subtitle1: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.1rem",
    },
  },
  subtitle2: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1rem",
    },
  },
  body1: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.1rem",
    },
  },
  body2: {
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1rem",
    },
  },
  green: {
    color: "#1AB739",
  },
  boldGreen: {
    color: "#1AB739",
    fontWeight: "bold",
  },
}));

const Beegrugraphy = (props) => {
  const classes = useStyles();
  /* Calculate class to use */

  var classToUse = classes.h1;

  switch (props.variant) {
    case "h1":
      classToUse = classes.h1;
      break;
    case "h2":
      classToUse = classes.h2;
      break;
    case "h3":
      classToUse = classes.h3;
      break;
    case "h4":
      classToUse = classes.h4;
      break;
    case "h5":
      classToUse = classes.h5;
      break;
    case "h6":
      classToUse = classes.h6;
      break;
    case "subtitle1":
      classToUse = classes.subtitle1;
      break;
    case "subtitle2":
      classToUse = classes.subtitle2;
      break;
    case "body1":
      classToUse = classes.body1;
      break;
    case "body2":
      classToUse = classes.body2;
      break;
    default:
      classToUse = classes.body1;
      break;
  }
  return (
    <React.Fragment>
      <div
        className={classes.root}
        style={{
          padding: props.rootPadding
            ? props.rootPadding
            : "0.5em 0em 0.5em 0em",
        }}
      >
        <CssBaseline />
        <Typography
          id={props.id}
          className={classNames(classToUse, props.className)}
          variant={props.variant}
          component={props.component ? props.component : ""}
          align={props.align ? props.align : "inherit"}
          classes={props.classes ? props.classes : {}}
          color={props.color ? props.color : "inherit"}
          display={props.display ? props.display : "initial"}
          gutterBottom={props.gutterBottom ? props.gutterBottom : false}
          noWrap={props.noWrap ? props.noWrap : false}
          paragraph={props.paragraph ? props.paragraph : false}
          variantMapping={props.variantMapping ? props.variantMapping : {}}
          style={{
            padding: props.padding ? props.padding : "0em",
            lineHeight: props.lineHeight ? props.lineHeight : "1em",
            fontSize: props.fontSize ? props.fontSize : undefined,
          }}
        >
          {props.bold ? (
            props.green ? (
              props.children.indexOf(props.green) == 0 ? (
                <b>
                  {props.children.slice(
                    0,
                    props.children.indexOf(props.green + " ")
                  )}
                  <span className={classes.green}>{props.green + " "}</span>
                  {props.children.slice(
                    props.children.indexOf(props.green + " ") +
                      props.green.length +
                      1
                  )}
                </b>
              ) : (
                <b>
                  {props.children.slice(
                    0,
                    props.children.indexOf(" " + props.green)
                  )}
                  <span className={classes.green}>{" " + props.green}</span>
                  {props.children.slice(
                    props.children.indexOf(" " + props.green) +
                      props.green.length +
                      1
                  )}
                </b>
              )
            ) : (
              <b>{props.children}</b>
            )
          ) : props.green ? (
            props.children.indexOf(props.green) == 0 ? (
              <span>
                {props.children.slice(
                  0,
                  props.children.indexOf(props.green + " ")
                )}
                <span
                  className={
                    props.boldGreen ? classes.boldGreen : classes.green
                  }
                >
                  {props.green + " "}
                </span>
                {props.children.slice(
                  props.children.indexOf(props.green + " ") +
                    props.green.length +
                    1
                )}
              </span>
            ) : (
              <span>
                {props.children.slice(
                  0,
                  props.children.indexOf(" " + props.green)
                )}
                <span
                  className={
                    props.boldGreen ? classes.boldGreen : classes.green
                  }
                >
                  {" " + props.green}
                </span>
                {props.children.slice(
                  props.children.indexOf(" " + props.green) +
                    props.green.length +
                    1
                )}
              </span>
            )
          ) : (
            props.children
          )}
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Beegrugraphy);
